import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import * as S from './styled';
import CommonButton from '../../../../components/UI/button';
import { GET_ALL_SITE_TARIFFS, INSERT_NEW_TARIFF } from '../../../../apollo/settings';
import { validateTariffValues } from '../../../../utils';
import { useContextInfo } from '../../../../context';
import { validateWholeNumbers } from '../../../../utils/validators';

const GeneralSettings = () => {
  const { t } = useTranslation();
  const { selectedSite, setSelectedSite } = useContextInfo()

  const [siteOptions, setSiteOptions] = useState(null);
  const [selectedSiteValue, setSelectSiteValue] = useState(selectedSite);
  //const [selectedSiteData, setSelectSiteData] = useState(null);
  const {data: tariffSiteData, loading: tariffLoading} = useQuery(
    GET_ALL_SITE_TARIFFS,
    {
      variables: {
        newestValidTariffOnly: true,
      },
    })

  const [insertNewTariff, { loading: insertLoading }] = useMutation(INSERT_NEW_TARIFF, {
    refetchQueries: [
      {
        query: GET_ALL_SITE_TARIFFS,
        variables: { newestValidTariffOnly: true },
      }
      ],
    awaitRefetchQueries: true
  });

  useEffect(() => {
    if (tariffSiteData?.allSiteTariffs?.length) {
      const options = tariffSiteData?.allSiteTariffs?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          name: item.name
        };
      });

      setSiteOptions(options);
      if(!selectedSiteValue || selectedSiteValue === ''){
        setSelectSiteValue(options[0]?.value);
      }
    }
  }, [tariffSiteData]);

/*  useEffect(() => {
    if (data?.sites?.length ) {
      console.log("set refetch data")
      const selectedSiteFromData = data.sites.find((s) => s?.id === selectedSite )
      console.log(selectedSiteFromData)
      setSelectSiteData(selectedSiteFromData)
    }
  }, [selectedSite, data]); */

  const [values, setValues] = useState([]);

  //if (loading) {
  //  return <CircularProgress />;
  //}

  const handlerInputData = (e, tariff_key, group_id) => {
    console.log(e.target.value)
    console.log(fromGermanFormat(e.target.value))
    const currentValues = [...values] ;

    const hasGroupId = currentValues.some(item => item.group_id === group_id);

    if(hasGroupId){
      const updatedValues = currentValues.map(
          (group_value) => {
            if(group_value.group_id === group_id){
              group_value[tariff_key] = e.target.value ? fromGermanFormat(e.target.value) : e.target.value
            }
            return group_value;
          }
      )
      setValues([...updatedValues]);
    } else {
      let newGroupData = {
        group_id: group_id,
      }
      newGroupData[tariff_key] = e.target.value ? fromGermanFormat(e.target.value) : e.target.value;
      const updatedValues = [
          newGroupData,
          ...currentValues
      ]
      setValues(updatedValues)
    }
  };

  const handlerSubmit = async (group_id, oldValues) => {
    //console.log("submit")
    //console.log(values)
    if (!Object.values(values)?.length) {
      return false;
    }
    let default_values = oldValues
    if(oldValues === null){
      default_values = {
        free_period_in_minutes: null,
        max_block_price: null,
        price_per_kwh: null,
        price_per_minute: null,
      }
    }

    const updateValues = values.find( (groups) => groups.group_id === group_id)

    console.log(values)

    const variables = {
      tariff: {
        group_id: group_id,
        site_id: selectedSiteValue,
        free_period_in_minutes: (updateValues?.free_period_in_minutes || default_values.free_period_in_minutes),
        max_block_price: (updateValues?.max_block_price || default_values.max_block_price),
        price_per_kwh: (updateValues?.price_per_kwh || default_values.price_per_kwh),
        price_per_minute: (updateValues?.price_per_minute || default_values.price_per_minute),
      }
    };


    await insertNewTariff({ variables });
    setValues([]);
  };

  if ( tariffLoading || insertLoading) {
    return <CircularProgress />;
  }

  const handlerSelect = (e) => {
    const currentValue = siteOptions?.find((item) => item.id === e.target.value);
    setSelectSiteValue(currentValue?.value);
    setSelectedSite(currentValue?.value);
  };

  const getCurrentTariff = (tariffArray) => {
    // Aktuelles Datum und Uhrzeit
    let now = new Date();
    now.setTime(now.getTime() + 1000)

    // Finde das Objekt mit dem neuesten "beginn_ts" <= dem aktuellen Zeitpunkt
     // Initialisiere mit einem sehr kleinen Datum
    return tariffArray.reduce((latest, current) => {
      const currentTimestamp = new Date(current.beginn_ts);
      const latestTimestamp = new Date(latest.beginn_ts);
      if (currentTimestamp <= now && currentTimestamp > latestTimestamp) {
        return current;
      } else {
        return latest;
      }
    }, tariffArray[0]);
  }

  const toGermanFormat = (num) => {
    if(num){
      return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 4 });
    } else {
      return num;
    }
  };

  const fromGermanFormat = (str) => {
    if(str){
      return parseFloat(str.replace('.', '').replace(',', '.'));
    } else {
      return str;
    }
  };

  return (
    <>
      <S.Title>{t('settings.select_site')}</S.Title>
      {siteOptions && (
          <S.SelectSiteStyle options={siteOptions} onChange={handlerSelect} value={selectedSiteValue} />
      )}
      {tariffSiteData?.allSiteTariffs?.length? (
          tariffSiteData?.allSiteTariffs?.filter((s) => s?.id === selectedSiteValue ).map( (site) =>
              (
                  site?.groups.map(
                      (group) =>
                          (<S.Block key={group.id+"_"+group.group_name}>
                            <S.SubTitle>{t('settings.group_tariff')} {group.group_name}</S.SubTitle>
                            {

                               (group?.tariffs && group?.tariffs.length > 0 ?
                                  group?.tariffs?.filter((x) => x?.id === getCurrentTariff(group?.tariffs).id ).map( (item)  => (
                                      <div key={item.id + "_tariff"}>
                                        <S.InputBlock>
                                          <S.SettingInput
                                              label={t("settings.pricePerkWh")}
                                              defaultValue={toGermanFormat(item?.price_per_kwh) }
                                              onInput={validateTariffValues}
                                              onChange={(e) => handlerInputData(e, "price_per_kwh", group.group_id)}
                                          />
                                          <S.SettingInput
                                              label={t("settings.pricePerMinute")}
                                              defaultValue={toGermanFormat(item?.price_per_minute) }
                                              onInput={validateTariffValues}
                                              onChange={(e) => handlerInputData(e, "price_per_minute", group.group_id)}
                                          />
                                          <S.SettingInput
                                              label={t("settings.freePeriodInMinutes")}
                                              defaultValue={item?.free_period_in_minutes}
                                              onInput={validateWholeNumbers}
                                              onChange={(e) => handlerInputData(e, "free_period_in_minutes", group.group_id)}
                                          />
                                          <S.SettingInput
                                              label={t("settings.maxBlockPrice")}
                                              defaultValue={toGermanFormat(item?.max_block_price)}
                                              onInput={validateTariffValues}
                                              onChange={(e) => handlerInputData(e, "max_block_price", group.group_id)}
                                          />
                                        </S.InputBlock>
                                        <CommonButton text={t('settings.submit')} onClick={() => handlerSubmit(group?.group_id, item)} />
                                      </div>
                                  ) )
                                  :  <div key={group.group_id + "_default_tariff"}>
                                     <S.InputBlock>
                                       <S.SettingInput
                                           label={t("settings.pricePerkWh")}
                                           defaultValue={null}
                                           onInput={validateTariffValues}
                                           onChange={(e) => handlerInputData(e, "price_per_kwh", group.group_id)}
                                       />
                                       <S.SettingInput
                                           label={t("settings.pricePerMinute")}
                                           defaultValue={null}
                                           onInput={validateTariffValues}
                                           onChange={(e) => handlerInputData(e, "price_per_minute", group.group_id)}
                                       />
                                       <S.SettingInput
                                           label={t("settings.freePeriodInMinutes")}
                                           defaultValue={null}
                                           onInput={validateWholeNumbers}
                                           onChange={(e) => handlerInputData(e, "free_period_in_minutes", group.group_id)}
                                       />
                                       <S.SettingInput
                                           label={t("settings.maxBlockPrice")}
                                           defaultValue={null}
                                           onInput={validateTariffValues}
                                           onChange={(e) => handlerInputData(e, "max_block_price", group.group_id)}
                                       />
                                     </S.InputBlock>
                                     <CommonButton text={t('settings.submit')} onClick={() => handlerSubmit(group?.group_id,null)} />
                                   </div> )


                            }
                          </S.Block>)
                  )
              )
        )
      ) : (
        <p>No sites...</p>
      )}
    </>
  );
};

export default GeneralSettings;
